import configData from './config/config.json';
import axios from 'axios';

const dsoapi = () => {
  const axiosObj = axios.create({
    baseURL: configData.DSOAPI_URL,
  });

  axiosObj.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        console.log(error.response);
        localStorage.removeItem('dsoaToken');
        localStorage.removeItem('dsoaClaims');
        window.location = '/';
      } else {
        return Promise.reject(error);
      }
    }
  );
  return axiosObj;
};

const get = async (url) => {
  try {
    const result = await dsoapi().get(url);
    return result;
  } catch (error) {}
};

const post = async (url, body) => {
  try {
    const result = await dsoapi().post(url, body);
    return result;
  } catch (error) {}
};

const put = async (url, body) => {
  try {
    const result = await dsoapi().put(url, body);
    return result;
  } catch (error) {}
};

const tokenGet = async (url) => {
  try {
    const result = await dsoapi().get(url, {
      headers: { 'x-dsoa-token': localStorage.getItem('dsoaToken') },
    });
    return result;
  } catch (error) {}
};

const tokenPost = async (url, body) => {
  try {
    const result = await dsoapi().post(url, body, {
      headers: { 'x-dsoa-token': localStorage.getItem('dsoaToken') },
    });
    return result;
  } catch (error) {}
};

const tokenPut = async (url, body) => {
  try {
    const result = await dsoapi().put(url, body, {
      headers: { 'x-dsoa-token': localStorage.getItem('dsoaToken') },
    });
    return result;
  } catch (error) {}
};

export const checkPromoCode = async (body) => await post('/promo/check', body);

export const courseByAbbreviation = async (abbreviation) =>
  await get(`/courses/abbreviation/${abbreviation}`);

export const coursesByAbbreviations = async (body) =>
  await post('/courses/abbreviations', body);

export const courseAddAdi12Info = async (uceId, body) =>
  await tokenPost(`/courses/addadi12info/${uceId}`, body);

export const courseAddBdiInfo = async (uceId, body) =>
  await tokenPost(`/courses/addbdiinfo/${uceId}`, body);

export const courseAddTvscaInfo = async (uceId, body) =>
  await tokenPost(`/courses/addtvscainfo/${uceId}`, body);

export const courseAddBdi4tnInfo = async (uceId, body) =>
  await tokenPost(`/courses/addbdi4tninfo/${uceId}`, body);

export const courseAddBdi8tnInfo = async (uceId, body) =>
  await tokenPost(`/courses/addbdi8tninfo/${uceId}`, body);

export const courseAddDdc6njInfo = async (uceId, body) =>
  await tokenPost(`/courses/addddc6njinfo/${uceId}`, body);

export const courseAddSddanjInfo = async (uceId, body) =>
  await tokenPost(`/courses/addsddanjinfo/${uceId}`, body);

export const courseAddTlsaeInfo = async (uceId, body) =>
  await tokenPost(`/courses/addtlsaeinfo/${uceId}`, body);

export const courseAddSecurityAnswers = async (uceId, body) =>
  await tokenPost(`/courses/securityanswers/${uceId}`, body);

export const courseAdi12ReasonsAttending = async () =>
  await get('/courses/adi12reasonsattending');

export const courseBdiReasonsAttending = async () =>
  await get('/courses/bdireasonsattending');

export const coursesByState = async (abbreviation) =>
  await get(`/courses/state/${abbreviation}`);

export const courseCanEnroll = async (abbreviation) =>
  await tokenGet(`/courses/canenroll/${abbreviation}`);

export const courseDlStatesOfRecord = async () =>
  await get('/courses/dlstateofrecords');

export const courseEnroll = async (abbreviation, body) =>
  await tokenPost(`/courses/enroll/${abbreviation}`, body);

export const courseCaCounties = async () => await get('/courses/cacounties');

export const courseCaCourthouses = async () =>
  await get('/courses/cacourthouses');

export const courseCaLawEnforcementAgencies = async () =>
  await get('/courses/calawenforcementagencies');

export const courseEyeColors = async () => await get('/courses/eyecolors');

export const courseFlCounties = async () => await get('/courses/flcounties');

export const courseTnCounties = async () => await get('/courses/tncounties');

export const courseFromEnrollment = async (uceId) =>
  await tokenGet(`/courses/enrollment/${uceId}`);

export const courseNextGatherInfo = async (uceId) =>
  await tokenGet(`/courses/nextgatherinfo/${uceId}`);

export const courseSecurityQuestions = async (uceId) =>
  await tokenGet(`/courses/securityquestions/${uceId}`);

export const coursePayment = async (uceId, body) =>
  await tokenPost(`/courses/payments/${uceId}`, body);

export const coursePage = async (hashLink) =>
  await tokenGet(`/coursepages/${hashLink}`);

export const coursePageAddTvscaEvaluation = async (hashLink, body) =>
  await tokenPut(`/coursepages/${hashLink}/addtvscaevaluation`, body);

export const coursePageAnswerSecurityQuestions = async (hashLink, body) =>
  await tokenPost(`/coursepages/${hashLink}/answersecurityquestions`, body);

export const coursePageAnswerChapterquizQuestions = async (hashLink, body) =>
  await tokenPost(`/coursepages/${hashLink}/answerchapterquizquestions`, body);

export const coursePageAnswerMiniquizQuestions = async (hashLink, body) =>
  await tokenPost(`/coursepages/${hashLink}/answerminiquizquestions`, body);

export const coursePageCompleteCourse = async (hashLink) =>
  await tokenPost(`/coursepages/${hashLink}/completecourse`, null);

export const coursePageGradeQuiz = async (hashLink, body) =>
  await tokenPost(`/coursepages/${hashLink}/gradequiz`, body);

export const coursePageNext = async (hashLink) =>
  await tokenGet(`/coursepages/${hashLink}/next`);

export const coursePagePrevious = async (hashLink) =>
  await tokenGet(`/coursepages/${hashLink}/previous`);

export const coursePageQuizReviewTvsca = async (hashLink) =>
  await tokenPost(`/coursepages/${hashLink}/quizreviewtvsca`, {});

export const coursePageQuizReviewDdc6Nj = async (hashLink) =>
  await tokenPost(`/coursepages/${hashLink}/quizreviewddc6nj`, {});

export const coursePageQuizReviewSddaNj = async (hashLink) =>
  await tokenPost(`/coursepages/${hashLink}/quizreviewsddanj`, {});

export const coursePageQuizScore = async (hashLink) =>
  await tokenGet(`/coursepages/${hashLink}/quizscore`);

export const coursePageSecondsRemaining = async (hashLink) =>
  await tokenGet(`/coursepages/${hashLink}/secondsremaining`);

export const courseStates = async () => await dsoapi().get('/courses/states');

export const helpPageBySite = async (site) => await get(`/helppages/${site}`);

export const helpPageBySiteSlug = async (site, titleSlug) =>
  await get(`/helppages/${site}/${titleSlug}`);

export const sendForgotPasswordEmail = async (request) =>
  await post('/sendforgotpasswordemail', request);

export const sendSupportEmail = async (request) =>
  await post('/support/sendemail', request);

export const sendSupportEmailLoggedIn = async (request) =>
  await tokenPost('/support/sendemailloggedin', request);

export const states = async () => await dsoapi().get('/states');

export const stateByAbbreviation = async (abbreviation) =>
  await get(`/states/${abbreviation}`);

export const studentCreate = async (request) =>
  await post('/students', request);

export const studentDashboard = async () =>
  await tokenGet('/students/dashboard');

export const studentData = async () => await tokenGet('/students/me');

export const studentDataUpdate = async (body) =>
  await tokenPut('/students/me', body);

export const studentEmailOptOut = async (emailLogId) =>
  await get(`/students/emailoptout/${emailLogId}`);

export const studentEmailOptOutToggle = async (emailLogId) =>
  await put(`/students/emailoptout/${emailLogId}`, null);

export const studentEmailExists = async (email) =>
  await get(`/students/emailexists/${email}`);

export const studentPasswordExists = async () =>
  await tokenGet(`/students/me/passwordexists`);

export const studentInfo = async () => await tokenGet('/students/me/info');

export const studentLogin = async (body) => await post('/students/login', body);

export const studentLoginAdmin = async (body) =>
  await tokenPost('/students/loginadmin', body);

export const studentLoginGoogle = async (body) =>
  await post('/students/logingoogle', body);

export const studentLogout = async () => await tokenPost('/students/logout');

export const studentResetPassword = async (request) =>
  await post('/students/resetpassword', request);

export const studentUpdatePassword = async (body) =>
  await tokenPut('/students/me/password', body);
