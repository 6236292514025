import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { courseByAbbreviation, courseCanEnroll, courseEnroll } from 'dsoapi';
import NextGatherInfoUrl from './GatherCourseInfo/NextGatherInfoUrl';
import RegistrationSteps from './RegistrationSteps';
import Video from './Video';
import { loggedIn } from './UserContext';
import { useAdSource } from './useAdSource';

const CourseDetails = ({
  site = 'DSOA',
  showRegistrationSteps = false,
  setTitle = () => {},
}) => {
  const [course, setCourse] = useState(null);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const { courseAbbreviation } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef(null);
  useAdSource();

  useEffect(() => {
    const fetchCourse = async () => {
      const response = await courseByAbbreviation(courseAbbreviation);
      if (response && response.data) {
        setCourse(response.data);
        setTitle(response.data.displayName);
      } else {
        navigate('/');
      }
    };
    fetchCourse();
  }, [setTitle, courseAbbreviation, navigate]);

  // Apply Semantic UI styling to tables after content is rendered
  useEffect(() => {
    if (contentRef.current && course) {
      const tables = contentRef.current.querySelectorAll('table');
      tables.forEach((table) => {
        // Add Semantic UI table classes
        table.classList.add('ui', 'table', 'striped', 'celled', 'fluid');
      });
    }
  }, [course]);

  const onEnroll = async () => {
    if (isEnrolling) {
      return;
    }
    setIsEnrolling(true);
    const canEnrollRes = await courseCanEnroll(course.abbreviation);
    if (canEnrollRes.data.canEnroll) {
      const enrollRes = await courseEnroll(course.abbreviation, {
        siteRegisteredWith: site === 'FEDS' ? 'fedsafe' : 'dsoa',
      }); // not being used for sdda yet
      const url = await NextGatherInfoUrl(
        enrollRes.data.userCourseEnrollmentId
      );
      navigate(url);
    } else {
      setIsEnrolling(false);
      navigate('/dashboard');
    }
  };

  const onSignup = () => {
    localStorage.setItem('signupCourse', course.abbreviation);
    navigate('/signup');
  };

  return (
    <>
      <div className="ui main container">
        <div className="course-details">
          {showRegistrationSteps && (
            <RegistrationSteps step={2} linkSelectCourse={true} />
          )}
          {course && (
            <>
              <div className="ui segment">
                <h1 className="ui center aligned header">
                  {course.displayName}
                </h1>
                <div className="ui divider"></div>
                {course.details.videoPath && (
                  <>
                    <Video
                      videoPath={course.details.videoPath}
                      posterPath={course.details.videoPosterPath}
                    />
                    <br />
                  </>
                )}
                <div
                  ref={contentRef}
                  dangerouslySetInnerHTML={{ __html: course.infoHtml }}
                />
                <div className="ui centered grid">
                  <div className="row">
                    <div className="column">
                      {loggedIn() ? (
                        <button
                          className="fluid ui primary button"
                          onClick={() => onEnroll()}
                          disabled={isEnrolling}
                        >
                          {isEnrolling ? 'Enrolling...' : 'Enroll'}
                        </button>
                      ) : (
                        <button
                          className="fluid ui primary button"
                          onClick={() => onSignup()}
                        >
                          Sign Up
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
