import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import {
  courseAddAdi12Info,
  courseAdi12ReasonsAttending,
  courseDlStatesOfRecord,
  studentData,
} from 'dsoapi';
import NextGatherInfoUrl from './NextGatherInfoUrl';

const GatherInfoADI12 = ({ uceId }) => {
  const [reasonsAttending, setReasonsAttending] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [citationNumber, setCitationNumber] = useState('');
  const [dlNumber, setDlNumber] = useState('');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const reasonsPromise = courseAdi12ReasonsAttending();
      const statesPromise = courseDlStatesOfRecord();
      const studentDataPromise = studentData();
      const reasonsResp = await reasonsPromise;
      const statesResp = await statesPromise;
      const studentResp = await studentDataPromise;
      setReasonsAttending(reasonsResp.data);
      setStates(statesResp.data);
      setSelectedState('FL');
      setDlNumber(studentResp.data.driverLicenseNumber);
    };
    fetchData();
  }, [uceId]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setShowValidation(true);
    if (
      selectedReason !== null &&
      selectedReason !== 0 &&
      citationNumber &&
      dlNumber &&
      selectedState &&
      selectedGender
    ) {
      setSubmitting(true);
      const request = {
        reasonAttending: selectedReason,
        citationNumber,
        driverLicenseNumber: dlNumber,
        dlStateOfRecord: selectedState,
        gender: selectedGender,
      };
      await courseAddAdi12Info(uceId, request);
      const url = await NextGatherInfoUrl(uceId);
      setSubmitting(false);
      navigate(url);
    }
  };

  function validateDLField() {
    if (dlNumber.length < 1) {
      return {
        isValid: false,
        message: 'Driver license number is required',
      };
    }
    if (selectedState === 'FL') {
      const regex = /^[a-zA-Z][0-9]{12}$/;
      if (!regex.test(dlNumber)) {
        return {
          isValid: false,
          message:
            'Florida drivers license numbers consist of one letter and 12 numbers formatted as A123456789012',
        };
      }
    }
    return {
      isValid: true,
      message: '',
    };
  }

  return (
    <form className="ui form" onSubmit={onSubmit}>
      <div className="ui container">
        <h4 className="ui dividing header">
          Enter ADI 12-Hour Course Information
        </h4>
        <div className="ui stackable grid">
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="reasonAttending">Reason Attending</label>
              <Dropdown
                name="reasonAttending"
                style={{ maxHeight: '27rem' }}
                placeholder="Select a reason for attending"
                fluid
                selection
                options={reasonsAttending}
                value={selectedReason}
                onChange={(_, { value }) => setSelectedReason(value)}
              />
              {showValidation && (!selectedReason || selectedReason === 0) && (
                <p className="error">reason attending is required</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="citationNumber">
                Citation, Ticket or Case Number
              </label>
              <input
                name="citationNumber"
                placeholder="Citation, Ticket or Case Number"
                value={citationNumber}
                onChange={(e) => setCitationNumber(e.target.value)}
              />
              {showValidation && !citationNumber && (
                <p className="error">
                  citation, ticket or case number is required
                </p>
              )}
            </div>
          </div>
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="dlStateOfRecord">
                Driver License State of Record (Where your license was issued)
              </label>
              <Dropdown
                name="dlStateOfRecord"
                style={{ maxHeight: '27rem' }}
                placeholder="Select state"
                fluid
                selection
                options={states}
                value={selectedState}
                onChange={(_, { value }) => setSelectedState(value)}
              />
              {showValidation && !selectedState && (
                <p className="error">dl state of record is required</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="dlNumber">Drivers License Number</label>
              <input
                name="dlNumber"
                placeholder="Drivers License Number"
                value={dlNumber}
                onChange={(e) => setDlNumber(e.target.value)}
              />
              {showValidation && !validateDLField().isValid && (
                <p className="error">{validateDLField().message}</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="gender">Gender</label>
              <Dropdown
                name="gender"
                style={{ maxHeight: '27rem' }}
                placeholder="Select gender"
                fluid
                selection
                options={[
                  { text: 'Male', value: 'M' },
                  { text: 'Female', value: 'F' },
                ]}
                value={selectedGender}
                onChange={(_, { value }) => setSelectedGender(value)}
              />
              {showValidation && !selectedGender && (
                <p className="error">gender is required</p>
              )}
            </div>
            <input
              className="ui primary button"
              type="submit"
              value={submitting ? 'Submitting...' : 'Submit'}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default GatherInfoADI12;
