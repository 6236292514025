import configData from '../../../apps/dsoa/src/config/config.json';
import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  sendSupportEmail,
  sendSupportEmailLoggedIn,
  studentData as getStudentInfo,
} from 'dsoapi';
import { loggedIn } from './UserContext';
import { useAdSource } from './useAdSource';

const yupEmail = yup
  .string()
  .email('Invalid email address')
  .required('Email is required');

const schema = yup
  .object({
    name: yup
      .string()
      .max(100, 'Name is too long')
      .required('Name is required'),
    emailAddress: yupEmail,
    phone: yup.string(),
    messageText: yup.string().required('Message is required'),
  })
  .required();

const Contact = ({ site, setTitle = () => {} }) => {
  const [messageSent, setMessageSent] = useState(false);
  useAdSource();
  setTitle('Contact');

  const recaptchaRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loggedIn()) {
      const fetchStudentInfo = async () => {
        const response = await getStudentInfo();
        const studentName = `${response.data.firstName} ${response.data.lastName}`;
        setValue('name', studentName);
        setValue('emailAddress', response.data.emailAddress);
        setValue('phone', response.data.phone);
      };
      fetchStudentInfo();
    }
  }, [setValue]);

  const onSubmit = async (data) => {
    setMessageSent(true);
    const reptchaToken = await recaptchaRef.current.executeAsync();
    if (reptchaToken) {
      const request = {
        ...data,
        recaptcha: reptchaToken,
        site,
      };
      if (loggedIn()) {
        await sendSupportEmailLoggedIn(request);
      } else {
        await sendSupportEmail(request);
      }
    } else {
      console.log('unable to get recaptcha token');
    }
  };

  return (
    <div className="ui container">
      <div className="ui center aligned container">
        <h3>Contact</h3>
      </div>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
            <div className="ui center aligned container">
              <i className="huge envelope icon"></i>
            </div>
            <div className="field">
              <label htmlFor="name">Name</label>
              <input {...register('name')} placeholder="Name" />
              <p className="error">{errors.name?.message}</p>
            </div>
            <div className="field">
              <label htmlFor="emailAddress">Email Address</label>
              <input
                {...register('emailAddress')}
                placeholder="Email Address"
              />
              <p className="error">{errors.emailAddress?.message}</p>
            </div>
            <div className="field">
              <label htmlFor="phone">Phone</label>
              <input {...register('phone')} placeholder="Phone" />
              <p className="error">{errors.phone?.message}</p>
            </div>
            <div className="field">
              <label htmlFor="messageText">Enter Message</label>
              <textarea
                {...register('messageText')}
                rows="8"
                cols="20"
                placeholder="Message"
                style={{ resize: 'none' }}
              />
              <p className="error">{errors.messageText?.message}</p>
            </div>
            <input
              className="ui primary button"
              type="submit"
              value={messageSent ? 'Message Sent' : 'Send'}
              disabled={messageSent}
            />
            <br />
            {messageSent && (
              <p>A staff member will reply to your email shortly.</p>
            )}
            <br />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LddqUEeAAAAAFObi8LK6j0X1AB-pSY5yl0YCVVF"
              size="invisible"
              badge="inline"
            />
          </form>
        </div>
        <div className="eight wide column">
          <div className="ui center aligned container">
            <i className="huge phone icon"></i>
          </div>
          <div style={{ height: '11px' }} />
          <table className="ui striped table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>9:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>9:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>9:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>9:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>9:00 AM - 5:00 PM</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>Closed</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>Closed</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div style={{ fontStyle: 'italic' }}>
                    Note: Hours above are in Eastern Time. Closed on holidays.
                  </div>
                </td>
              </tr>
              {site === 'DSOA' && (
                <tr>
                  <td colSpan="2">
                    <div style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                      California Traffic Violator School Business Hours:
                    </div>
                    <div style={{ fontStyle: 'italic' }}>
                      Weekdays 8am to 4pm Pacific Time. Closed on all weekends
                      and holidays.
                    </div>
                    <div style={{ fontStyle: 'italic' }}>
                      License number # E0349 229 Tewksbury Ave Ste C, Richmond,
                      CA 94801-3829
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
          {site === 'FEDS' ? (
            <p>Call us at: 1-888-497-1755</p>
          ) : (
            <p>Call us at: 1-888-541-5576</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
